import { Icon } from '@sicpama/core-components';
import { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import DatabaseIcon from 'src/components/Icons/database';
import HomeIcon from 'src/components/Icons/home';
import { IconProps } from 'src/components/Icons/Icon.interface';
import InventoryIcon from 'src/components/Icons/inventory';
import MenuIcon from 'src/components/Icons/menu';
import PrinterIcon from 'src/components/Icons/printer';
import ReportIcon from 'src/components/Icons/report';
import TransactionIcon from 'src/components/Icons/transaction';
import Select from 'src/components/System/Select';
import { ConfigContext } from 'src/contexts';
import { ROUTES } from 'src/helpers/constants';
import { StoreSelector, useStoreState } from 'src/States';
import { useMenuState } from 'src/States/Menu/menu';
import { useThermalPrinterState } from 'src/States/ThermalPrinter/ThermalPrinterState';
import { STORE_ATTRIBUTE_NAME } from 'src/submodules/sicpama-shared';

const translationPrefix = 'table-status';

interface NavbarLinkProps {
  readonly to: string;
  readonly Icon: FC<IconProps> | any;
  readonly text: string;
  readonly selected: boolean;
  readonly importedIcon?: boolean;
}

function NavbarLink({ to, Icon, text, selected, importedIcon }: NavbarLinkProps) {
  const backgroundIcon = selected === true ? 'bg-indigo-500' : 'bg-white';
  const iconColor = selected === true ? 'white' : '#5b5b5b';
  return (
    <NavLink to={to} className="w-full flex flex-col justify-center items-center  mb-8">
      <div
        className={`${backgroundIcon} w-[30px] h-[30px] flex justify-center items-center rounded mb-2`}
      >
        {importedIcon ? Icon : <Icon color={iconColor} className=" h-[20px] w-[20px] mx-auto" />}
      </div>
      <p className="text-center font-medium text-[13px] text-gray-500">{text}</p>
    </NavLink>
  );
}

const Navbar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { storeData } = useStoreState();
  const inventoryEnabledAttr = useStoreState(
    StoreSelector.getAttribute(STORE_ATTRIBUTE_NAME.IS_INVENTORY_ENABLED),
  );
  const isInventoryEnabled = inventoryEnabledAttr?.value === 'true';
  const kdsHomePathAttr = useStoreState(
    StoreSelector.getAttribute(STORE_ATTRIBUTE_NAME.KDS_HOME_PAGE_PATH),
  );

  const kdsHomePath = kdsHomePathAttr?.value;

  const { setThermalPrinter } = useThermalPrinterState();
  const { changeAppLanguage, currentAppLanguage } = useContext(ConfigContext);

  const logoUrl = useMemo(() => {
    return storeData?.countryCode === 'KR'
      ? `${process.env.PUBLIC_URL}/assets/images/sicpamaLogo.png`
      : 'https://cdn.sicpama.com/sicpama-assets/raptor_logo.png';
  }, [storeData]);

  return (
    <div className="min-w-[100px] max-w-[100px] h-screen overflow-scroll no-scrollbar flex flex-col  shadow-xl bg-white border-r border-gray-300 z-[1000]">
      <img
        className="w-full mt-6 mb-[20px] px-[18px] max-h-[63px]"
        src={logoUrl}
        alt="SicpamaLogo"
      />
      <div className="flex flex-col flex-grow px-[18px]">
        <NavbarLink
          to={kdsHomePath ?? ROUTES.TABLE_STATUS}
          text={
            kdsHomePath && kdsHomePath !== ROUTES.TABLE_STATUS
              ? t(`${translationPrefix}.order-status`)
              : t(`${translationPrefix}.table-status`)
          }
          Icon={HomeIcon}
          selected={location.pathname.includes(kdsHomePath ?? ROUTES.TABLE_STATUS)}
        />
        <NavbarLink
          to={ROUTES.MENU_MANAGEMENT}
          text={t(`${translationPrefix}.product-management`)}
          Icon={MenuIcon}
          selected={location.pathname.includes(ROUTES.MENU_MANAGEMENT)}
        />
        <NavbarLink
          to={ROUTES.SALE_STATUS}
          text={t(`${translationPrefix}.sales-status`)}
          Icon={ReportIcon}
          selected={location.pathname.includes(ROUTES.SALE_STATUS)}
        />
        <NavbarLink
          to={ROUTES.TRANSACTION_HISTORY}
          text={t(`${translationPrefix}.transaction-history`)}
          Icon={TransactionIcon}
          selected={location.pathname.includes(ROUTES.TRANSACTION_HISTORY)}
        />
        <NavbarLink
          to={ROUTES.DATA_REPORT}
          text={t(`${translationPrefix}.data-report`)}
          Icon={DatabaseIcon}
          selected={location.pathname.includes(ROUTES.DATA_REPORT)}
        />
        <NavbarLink
          to={ROUTES.STORE_INFO}
          text={t('navigation_bar.setting')}
          Icon={
            <Icon
              name="Cog"
              size={28}
              type="outline"
              color={location.pathname.includes(ROUTES.STORE_INFO) ? 'white' : 'black'}
            />
          }
          importedIcon
          selected={location.pathname.includes(ROUTES.STORE_INFO)}
        />
        {isInventoryEnabled && (
          <NavbarLink
            to={ROUTES.INVENTORY_MANAGEMENT}
            text={t(`${translationPrefix}.inventory`)}
            Icon={InventoryIcon}
            selected={location.pathname.includes(ROUTES.INVENTORY_MANAGEMENT)}
          />
        )}
        <button
          className="w-full flex flex-col justify-center items-center pb-5 px-2"
          onClick={setThermalPrinter}
        >
          <PrinterIcon color="#5b5b5b" className="w-[20px] h-[20px]" />
          <p className="text-center text-sm text-gray-500">
            {t(`${translationPrefix}.connect-printer`)}
          </p>
        </button>
      </div>
      <Select
        className="grow-0 mb-4"
        labelClassName="justify-center"
        items={[
          {
            value: 'en',
            label: '🇬🇧',
          },
          {
            value: 'zh',
            label: '🇨🇳',
          },
          {
            value: 'kr',
            label: '🇰🇷',
          },
        ]}
        onSelect={(value: string) => {
          changeAppLanguage(value);
        }}
        value={currentAppLanguage}
      />
      <NavLink to={ROUTES.LOGIN} className="mt-auto mb-4">
        <p
          onClick={() => {
            localStorage.removeItem('ACCESS_TOKEN');
            localStorage.removeItem('STORE_ID');
            useMenuState.getState().clearState();
          }}
          className="text-center text-sm text-gray-500"
        >
          {t(`${translationPrefix}.log-out`)}
        </p>
      </NavLink>
    </div>
  );
};

export default Navbar;
