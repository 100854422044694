import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import AudioPlayer from 'src/components/AudioPlayer/AudioPlayer';
import AudioPlayerAlertModal from 'src/components/AudioPlayer/AudioPlayerAlertModal';
import EventListener from 'src/components/EventListener/EventListener';
import GlobalLoader from 'src/components/GlobalLoader/GlobalLoader';
import { ROUTES } from 'src/helpers/constants';
import Navbar from 'src/pages/table-status/Components/Navbar/Navbar';
import {
  useNotificationOrderState,
  useOrderState,
  useStoreState,
  useStoreTableState,
} from 'src/States';
import { useAudioPlayerState } from 'src/States/AudioPlayer';
import {
  BaseKdsData,
  ClearTableKdsData,
  ItemUpdateKdsData,
  JoinTableKdsData,
  KDS_EVENT,
  KdsEvent,
  KickKdsData,
  OrderUpdateKdsData,
  PrintOrdersKdsData,
  TablePaidKdsData,
} from 'src/submodules/sicpama-shared';

import { useThermalPrinterState } from '../States/ThermalPrinter/ThermalPrinterState';

const MainContext = createContext<any>({
  displayNavbar: true,
  setDisplayNavbar: (state: boolean) => {
    console.info('setDisplayNavbar', state);
  },
});

const MainLayout = ({}) => {
  const { t } = useTranslation();
  const [displayNavbar, setDisplayNavbar] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const storeData = useStoreState((state) => state.storeData);
  const getStoreTableData = useStoreTableState((state) => state.getStoreTableData);
  const getOrdersData = useOrderState((state) => state.getOrdersData);
  const getOrderData = useOrderState((state) => state.getOrderData);
  const getCompletedOrdersData = useOrderState((state) => state.getCompletedOrdersData);
  const getOrdersAlertData = useNotificationOrderState((state) => state.getOrdersAlertData);
  const getInitialOrdersAlertData = useNotificationOrderState(
    (state) => state.getInitialOrdersAlertData,
  );
  const getAlertsEnabled = useNotificationOrderState((state) => state.getAlertsEnabled);
  const alertsEnabled = useNotificationOrderState((state) => state.alertsEnabled);
  const cleanUp = useNotificationOrderState((state) => state.cleanUp);

  const setPlayAudio = useAudioPlayerState((state) => state.setPlayAudio);
  const printOrders = useThermalPrinterState((state) => state.printOrders);

  const { setThermalPrinter } = useThermalPrinterState();

  const initializeAppData = useCallback(async () => {
    // Get store info
    await AudioPlayer.instance().initAudioBuffer(
      `https://sicpama-images-staging.s3.ap-northeast-2.amazonaws.com/new-order.mp3`,
    );

    if (!window.location.pathname.includes(ROUTES.MENU_MANAGEMENT)) {
      await getStoreTableData(storeData.id);
      await getOrdersData(storeData.id);

      // If enabled fetch the alert data taking into account any already viewed stored in local storage
      const enabled = await getAlertsEnabled();
      if (enabled) {
        const count = getInitialOrdersAlertData();
        if (count > 0) {
          setPlayAudio(true);
        }
      }
      // Get completed orders data
      await getCompletedOrdersData(storeData.id);
    }

    setInitialized(true);

    //auto connect to printer silently if already connected once
    setThermalPrinter();
  }, [
    storeData.id,
    getStoreTableData,
    getOrdersData,
    getAlertsEnabled,
    getInitialOrdersAlertData,
    getCompletedOrdersData,
    setPlayAudio,
    setInitialized,
    setThermalPrinter,
  ]);

  useEffect(() => {
    try {
      initializeAppData();
    } catch (e) {
      console.log(e);
    }
  }, [initializeAppData]);

  const handleTableEvent =
    <T extends BaseKdsData>(event: KDS_EVENT) =>
    async (message: KdsEvent<T>) => {
      const tableId = message.data.storeTableId;
      await getStoreTableData(storeData.id);
      await getOrderData(storeData.id, tableId);
      if (alertsEnabled) {
        getOrdersAlertData();
      }
      if (event === KDS_EVENT.CLEAR_TABLE) {
        cleanUp();
        await getCompletedOrdersData(storeData.id);
      }
      if (event === KDS_EVENT.TABLE_PAID) {
        if (alertsEnabled) {
          getOrdersAlertData();
        }
        setPlayAudio(true);
      }
    };

  const handlePrintOrdersEvent = () => async (message: KdsEvent<PrintOrdersKdsData>) => {
    if (message.data.order) {
      printOrders(t, message.data);
    }
  };

  const key = (event: KDS_EVENT) => `${storeData.id}_${event}-event`;
  const channelName = () => `KDS_${storeData?.id?.toString()}`;

  const renderStoreEventListener = <T extends BaseKdsData>(event: KDS_EVENT) => {
    return (
      <div>
        <EventListener
          key={key(event)}
          channelName={channelName()}
          msgKey={KDS_EVENT[event]}
          callBackFun={handleTableEvent<T>(event)}
        />
      </div>
    );
  };

  const renderPrintOrdersEventListener = (event: KDS_EVENT) => {
    return (
      <div>
        <EventListener
          key={key(event)}
          channelName={channelName()}
          msgKey={KDS_EVENT[event]}
          callBackFun={handlePrintOrdersEvent()}
        />
      </div>
    );
  };

  if (!initialized) {
    return (
      <div className="w-screen h-screen">
        <GlobalLoader open={true} />
      </div>
    );
  }

  return (
    <MainContext.Provider
      value={{
        displayNavbar,
        setDisplayNavbar,
      }}
    >
      <AudioPlayerAlertModal />
      <div key="renderStoreEventListener-table-paid">
        {renderStoreEventListener<TablePaidKdsData>(KDS_EVENT.TABLE_PAID)}
      </div>
      <div key="renderStoreEventListener-join-table">
        {renderStoreEventListener<JoinTableKdsData>(KDS_EVENT.JOIN_TABLE)}
      </div>
      <div key="renderStoreEventListener-kick-diner">
        {renderStoreEventListener<KickKdsData>(KDS_EVENT.KICK_DINER)}
      </div>
      <div key="renderStoreEventListener-clear-table">
        {renderStoreEventListener<ClearTableKdsData>(KDS_EVENT.CLEAR_TABLE)}
      </div>
      <div key="renderStoreEventListener-order-update">
        {renderStoreEventListener<OrderUpdateKdsData>(KDS_EVENT.ORDER_UPDATE)}
      </div>
      <div key="renderStoreEventListener-item-served">
        {renderStoreEventListener<ItemUpdateKdsData>(KDS_EVENT.ITEM_UPDATE)}
      </div>
      <div key="renderStoreEventListener-print-orders">
        {renderPrintOrdersEventListener(KDS_EVENT.PRINT_ORDERS)}
      </div>
      <div className="w-full min-h-screen flex flex-row bg-[#F2F5F9]">
        {displayNavbar && <Navbar />}
        <Outlet />
      </div>
    </MainContext.Provider>
  );
};

export default MainLayout;

export const useMainContext = () => {
  const context = useContext(MainContext);
  if (!context) {
    throw new Error('useMainContext must be used within a MainContext');
  }
  return context;
};
